.main-pera {
    padding: 100px;
    border: 1px solid red;
}

.Single {
    align-items: center;
    text-align: center;
}


.Single img {
    margin-top: 100px;
    border-radius: 10px;
}