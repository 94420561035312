.login-container {
    padding: 20px;
  }
  
  .login-form, .add-user-form, .publish-section, .articles-section {
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 10px;
  }
  
  .input, .input-file, .textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .buttonn {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .buttonn.delete-button {
    background-color: #dc3545;
  }
  
  .articles-section {
    margin-top: 20px;
  }
  
  .articles-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .article-card {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: calc(33.333% - 20px);
    box-sizing: border-box;
  }
  
  .article-card img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .article-card h4 {
    margin: 10px 0;
    font-size: 1.2em;
  }
  
  .article-card p {
    margin: 5px 0;
  }
  