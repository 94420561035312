.timeline{
    position: relative;
    max-width: 1200px;
    margin: 100px auto;
}

.container2 {
    padding: 10px 50px;
    position: relative;
    width: 50%;
    animation: movedown 1s linear forwards;
    opacity: 0;
}

@keyframes movedown {
    0% {
        opacity: 1;
        transform: translateY(-30px);
    }
 100% {
    opacity: 1;
    transform: translateY(0px);
 }   

}



.container2:nth-child(2){
    animation-delay: 1.5s;
}

.container2:nth-child(3){
    animation-delay: 3s;
}


.container2:nth-child(4){
    animation-delay: 4s;
}


.text-box {
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 6px;
    font-size: 15px;
    border: 1px solid #ddd;
}


.left {
    left: 50%;
}

.right {
    left: 0;
}


.container2 img {
    position: absolute;
    width: 40px;
    border-radius: 50%;
    right: -20px;
    top: 32px;
    z-index: 10;
}


.left img {
 left: -20px;
}


.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 100%;
    background-color: #ddd;
    top: 0;
    left: 50%;
    margin-left: -3px;
    z-index: -1;
    animation: moveline 5s linear forwards;
}

@keyframes moveline {
    0% {
        height: 0;
    }

    100% {
        height: 100%;
    }
}

.text-box h2 {
    font-weight: 600;
}

.text-box small {
    display: inline-block;
    margin-bottom: 15px;
}

.right-arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid rgb(231, 231, 231);
    right: 35px;
}


.left-arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid rgb(231, 231, 231);
    left: 35px;
}



@media screen and (max-width:600px) {
    
   .timeline{
    margin: 50px auto;
   }

   .timeline::after {
    left: 31px;
   }


   .container2 {
    width: 100%;
    padding-left: 80px;
    padding-right: 25px;
   }

   .text-box {
    font-size: 13px;
   }

   .left {
    left: 0;
   }

   .left img, .right img {
    left: 10px;
   }

   .left-arrow,.right-arrow {
    border-right: 15px solid #ddd;
    border-left: 0;
    left: 65px;
   }

}



