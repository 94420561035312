


.services__modal-services {
    display: flex;

    margin-bottom: var(--mb-2);
    text-align: center;
}

.services-modal-services {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
    margin-bottom: 20px;

}

.services__modal-icon {
    font-size: 1.5rem;
    font-weight: var(--font-medium);
}

.services__modal-info {

    gap: 0.5rem;
    margin-bottom: var(--mb-2);
}
 

@media screen and (max-width: 768px) {
    .services__modal-services {
     display: block;
    }
}